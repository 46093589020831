<template>
  <div>
    <div class="form-group-radio__label">
      <label>{{groupLabel}}</label>
    </div>
    <div class="form-group-radio">
      <RadioButton v-for="item in items" :name="name" :label="item.label" :key="item.value" :value="item.value" :selected="selected" :groupLabel="groupLabel" @change="changeValue "/>
    </div>
    <AppError v-if="errors" :errors="errors" :field="field" />
  </div>
</template>
<script>
import RadioButton from '@/components/forms/RadioButton';
import AppError from '@/components/AppError';


export default {
  components: {
    RadioButton,
    AppError,
  },
  methods: {
    changeValue(newValue) {
      this.$emit('input', newValue);
    },
  },
  props: ['items', 'name', 'groupLabel', 'selected', 'field', 'errors'],
};
</script>
