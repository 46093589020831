<template>
  <div class="client-project-form__content mb-4">
    <AppInput
        v-model="project.name"
        class="mb-2"
        :label="$t('client-project-name')"
        field="name"
        :placeholder="$t('client-project-name-plholder')"
        :errors="errors"
    />
    <AppTextarea
        :id="$t('project')"
        v-model="project.description"
        class="mb-2 client-project-description"
        :label="$t('cp-description')"
        :placeholder="$t('cp-description-placeholder')"
        field="description"
        :errors="errors"
    />
    <div class="row">
      <div class="col-md-4 col-xs-12">
        <AppDatepicker
            :id="$t('project')"
            v-model="project.start_date"
            class="a-form-group w-100 input-white"
            :label="$t('cp-start-date')"
            :labelClass="true"
            field="start_date"
            :errors="errors"
        />
      </div>
      <div class="col-md-4 col-xs-12">
        <AppDatepicker
            :id="$t('project')"
            v-model="project.end_date"
            class="a-form-group w-100 input-white"
            :label="$t('cp-end-date')"
            :labelClass="true"
            field="end_date"
            :errors="errors"
        />
      </div>
      <div class="col-md-4 col-xs-12">
        <div class="project-cost">
          <AppInput
              v-model="project.cost_max"
              class="mb-2"
              type="number"
              :label="$t('cp-cost')"
              min="0"
              field="cost_max"
              :placeholder="$t('cp-cost-plholder')"
              :errors="errors"
          />
          <div class="form-group mb-2">
            <label class="">&nbsp;</label>
            <span>{{ currency }}{{ $t('per-hour') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppInput from '@/components/forms/AppInput';
import AppTextarea from '@/components/forms/AppTextarea';
import AppDatepicker from '@/components/forms/AppDatepicker';

export default {
  name: 'project-template',
  i18nOptions: { namespaces: 'project-modal' },
  components: {
    AppInput,
    AppTextarea,
    AppDatepicker,
  },
  data() {
    return {
      currency: this.$appConfig.currency,
    };
  },
  props: ['project', 'errors'],
  methods: {

  },
};
</script>
