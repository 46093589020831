<template>
  <div
    class="form-group"
    :class="{
      'has-error': errors && errors.has(field),
      'has-notice': errors && errors.hasNotice(field)
    }"
  >
    <label v-if="label" :for="field"
      >{{ label }} <i v-if="optional">{{ $t(" - Optional") }} </i>
      <div v-if="infoIcon" v-tooltip.hover="hoverInfoText" class="small-info-circle">i</div></label
    >
    <textarea
      :id="setInputId"
      :class="inputClassName"
      :value="value"
      :placeholder="placeholder"
      :disabled="disabled"
      @input="$emit('input', $event.target.value)"
      @change="$emit('change', $event.target.value)"
    ></textarea>
    <AppError :errors="errors" :field="field" v-if="field && errors" />
  </div>
</template>

<script>
import { tooltip } from 'uiv';
import AppError from '../AppError';

export default {
  components: {
    AppError,
  },
  directives: {
    tooltip,
  },
  props: [
    'id',
    'errors',
    'label',
    'value',
    'field',
    'placeholder',
    'className',
    'disabled',
    'optional',
    'infoIcon',
    'hoverInfoText',
  ],
  computed: {
    inputClassName() {
      return `form-control ${this.className}`;
    },
    setInputId() {
      return this.dynamicID(this.id) || this.dynamicID(this.field);
    },
  },
  methods: {
    dynamicID(id) {
      return id + Math.floor(Math.random() * Date.now().toString());
    },
  },
};
</script>
