<template>
  <div v-if="desired_availability && weekDays">
    <label>{{ $t("desired") }}</label>
    <div class="availability">
      <div class="availability-week">
        <div class="availability-week__days">
          <div v-for="day in week" :key="day" class="week-column">
            {{ $t(day) }}
          </div>
        </div>
        <div class="availability-week__state">
          <div v-for="index in arrayAm" :key="index">
            <div
              @click.prevent="addTime(weekDays[index - 1].id)"
              class="state state-am"
              :class="{ active: desired_availability.indexOf(weekDays[index - 1].id) >= 0 }"
            >
              {{ weekDays[index - 1].ampm }}
            </div>

            <div
              @click.prevent="addTime(weekDays[index].id)"
              class="state state-pm"
              :class="{ active: desired_availability.indexOf(weekDays[index].id) >= 0 }"
            >
              {{ weekDays[index].ampm }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="errors && errors.has(field)" class="availability-week__errors">
      <span class="help-block">
        <p class="help-block__error text-danger">
          <DangerIcon />
          <small>{{ $t('desired-availability-required') }}</small>
        </p>
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DangerIcon from '../icons/DangerIcon';

export default {
  name: 'days-in-week',
  i18nOptions: { namespaces: 'phase-form' },
  components: { DangerIcon },
  data() {
    return {
      week: ['mo', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
      arrayAm: [1, 3, 5, 7, 9, 11, 13],
    };
  },
  computed: {
    ...mapState({
      weekDays: state => state.weekDays.weekDays,
    }),
  },
  props: ['desired_availability', 'disabled', 'errors', 'field'],
  methods: {
    addTime(index) {
      if (!this.disabled) {
        this.$emit('change');
        const checkIndex = this.desired_availability.indexOf(index);
        if (checkIndex < 0) {
          this.desired_availability.push(index);
          this.$emit('updateProps', this.desired_availability);
        } else {
          this.desired_availability.splice(checkIndex, 1);
          this.$emit('updateProps', this.desired_availability);
        }
      }
    },
  },
};
</script>
