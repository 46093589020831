<template>
  <div>
    <div class="">
      <label>{{label}} <i v-if="optional">{{$t(" - Optional")}}</i></label>
    </div>
    <tinymce-editor
        api-key="ffceq63r4f8ux7o18z1uh2hab5h9o2nney70hmaq18h0bmuc"
        :class="{ 'hideToolbar' : hideToolbar }"
        v-model="contentVal"
        :init="{
         height: 300,
         menubar: false,
         block_formats: 'Heading=h4;Paragraph=p',
         plugins: [
           'advlist autolink lists link image charmap print preview anchor',
           'searchreplace visualblocks code fullscreen',
           'insertdatetime media table paste code help wordcount emoticons'
         ],
         paste_as_text: true,
         resize: false,
         toolbar:
           'undo redo | formatselect | bold italic |  \
           bullist numlist | removeformat'
       }"></tinymce-editor>
    <AppError :errors="errors" :field="field" v-if="errors"/>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import AppError from '../AppError';

export default {
  components: {
    AppError,
    'tinymce-editor': Editor,
  },
  props: ['errors', 'value', 'label', 'field', 'optional', 'hideToolbar'],
  computed: {
    contentVal: {
      get() {
        return this.value ? this.value : '';
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>
