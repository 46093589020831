<template>
  <div class="form-group-radio__item">
    <label class="radio">
      <input type="radio" :value="value" :name="name" v-model="radioButtonValue">
      <span>{{ label }}</span>
    </label>
  </div>
</template>

<script>

export default {
  components: {},
  computed: {
    radioButtonValue: {
      get() {
        return this.selected;
      },
      set() {
        // Communicate the change to parent component so that selectedValue can be updated
        this.$emit('change', this.value);
      },
    },
  },
  props: ['name', 'label', 'value', 'selected'],
};
</script>
