import { render, staticRenderFns } from "./RoleIcon.vue?vue&type=template&id=14ec4da3&xmlns=http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg&"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports